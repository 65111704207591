function importAll(r: { keys: () => any }) {
  r.keys().forEach(r);
}

import '../stylesheets/components.scss';

importAll(
  // @ts-expect-error
  // eslint-disable-next-line no-undef
  require.context('../../components', true, /component\.(ts|js|s?css)$/),
);
